import axios from 'axios';

const USER_API_BASE_URL = 'https://api.iconnect.net.ua/';

class AuthService {

    login(credentials) {
        return axios.post(USER_API_BASE_URL + 'auth', credentials);
    }

    isLoggedIn() {
        var userInfo = this.getUserInfo()
        if (userInfo) {
            if (userInfo.hasOwnProperty("access_token")) {
                return true;
            }
        }
        return false;
    }

    getUserInfo() {
        return JSON.parse(localStorage.getItem("userInfo"));
    }

    getAuthHeader() {
        return {headers: {Authorization: 'Bearer ' + this.getUserInfo().access_token }};
    }

    logOut() {
        localStorage.removeItem("userInfo");
        return axios.post(USER_API_BASE_URL + 'logout', {}, this.getAuthHeader());
    }
}

export default new AuthService();
