import axios from 'axios';
import AuthService from './AuthService';

const USER_API_BASE_URL = 'https://api.iconnect.net.ua/api/v1/';

class UserService {

    constructor(props) {
        this.state = {
            userData: ''
        }
    }

    fetchUserData() {
        return axios.get(USER_API_BASE_URL + 'users/' + AuthService.getUserInfo().login + '/', AuthService.getAuthHeader())
    }
    
    fetchPayments() {
        return axios.get(USER_API_BASE_URL + 'payments/' + AuthService.getUserInfo().login + '/', AuthService.getAuthHeader())
    }

    fetchFees() {
        return axios.get(USER_API_BASE_URL + 'fees/' + AuthService.getUserInfo().login + '/', AuthService.getAuthHeader())
    }
    
    paymentData() {
        return axios.get(USER_API_BASE_URL + 'payment_data/' + AuthService.getUserInfo().uid + '/', AuthService.getAuthHeader())
    }

    cardPayment(data) {
        return axios.post(USER_API_BASE_URL + 'card_payment/' + AuthService.getUserInfo().login + '/', data, AuthService.getAuthHeader());
    }
    
    trustedPaymentCheck() {
        return axios.get(USER_API_BASE_URL + 'trusted_payment_check/' + AuthService.getUserInfo().uid + '/', AuthService.getAuthHeader())
    }
    
    trustedPaymentUse(data) {
        return axios.post(USER_API_BASE_URL + 'trusted_payment_use/' + AuthService.getUserInfo().login + '/', data, AuthService.getAuthHeader());
    }
    
    loyaltyPaymentCheck() {
        return axios.get(USER_API_BASE_URL + 'loyalty_payment_check/' + AuthService.getUserInfo().login + '/', AuthService.getAuthHeader())
    }
    
    loyaltyPaymentUse(data) {
        return axios.post(USER_API_BASE_URL + 'loyalty_payment_use/' + AuthService.getUserInfo().login + '/', data, AuthService.getAuthHeader());
    }
    
    megabonusCheck() {
        return axios.get(USER_API_BASE_URL + 'megabonus_check/' + AuthService.getUserInfo().uid + '/', AuthService.getAuthHeader())
    }
    
    megabonusUse(data) {
        return axios.post(USER_API_BASE_URL + 'megabonus_use/' + AuthService.getUserInfo().login + '/', data, AuthService.getAuthHeader());
    }
    
    fetchMessages() {
        return axios.get(USER_API_BASE_URL + 'messages/' + AuthService.getUserInfo().uid + '/', AuthService.getAuthHeader())
    }
    
    fetchMessage(message_id) {
        return axios.get(USER_API_BASE_URL + 'message/' + AuthService.getUserInfo().uid + '/' + message_id + '/', AuthService.getAuthHeader())
    }
    
    replyOnMessage(data) {
        return axios.post(USER_API_BASE_URL + 'message/reply/' + AuthService.getUserInfo().login + '/', data, AuthService.getAuthHeader());
    }
    
    createMessage(data) {
        return axios.post(USER_API_BASE_URL + 'messages/create/' + AuthService.getUserInfo().login + '/', data, AuthService.getAuthHeader());
        //return fetch(USER_API_BASE_URL + 'messages/create/', AuthService.getAuthHeader(), {method: 'POST', body: data});
    }

    getAttachment(data) {
   		fetch(USER_API_BASE_URL + 'attachment/' + data.uid + '/' + data.attachment_id + '/', AuthService.getAuthHeader(), {responseType: 'blob'})
        .then((response) => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = data.filename;
                a.click();
            });
        });
    }
    
    internetSuspendCheck() {
        return axios.get(USER_API_BASE_URL + 'internet_suspend_check/' + AuthService.getUserInfo().uid + '/', AuthService.getAuthHeader())
    }
    
    internetSuspendUse(data) {
        return axios.post(USER_API_BASE_URL + 'internet_suspend_use/' + AuthService.getUserInfo().uid + '/', data, AuthService.getAuthHeader());
    }
    
    internetUnSuspendUse(data) {
        return axios.post(USER_API_BASE_URL + 'internet_unsuspend_use/' + AuthService.getUserInfo().uid + '/', data, AuthService.getAuthHeader());
    }
}

export default new UserService();
