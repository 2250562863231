import React, { useEffect, useState } from 'react';
import UserService from "../../service/UserService";
import Typography from '@material-ui/core/Typography';
import NavBar from "../NavBar";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Container from '@material-ui/core/Container';
import Chip from '@material-ui/core/Chip';
import { styleBubble } from '../../styles';
import LinearProgress from '@material-ui/core/LinearProgress';


const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3,2),
    margin: theme.spacing(1,1),
    backgroundColor: '#fffaf2',
    borderRadius: '1rem',
  },
  toolbar: theme.mixins.toolbar,
}));

const style ={
    display: 'flex',
    justifyContent: 'left'
}

const UserInfoComponent = (props) => {

    const [data, setData] = useState({});
    const [users_pi, setUsersPi] = useState({});
    const [bill, setBill] = useState({});
    

    const classes = useStyles();


    useEffect(() => {
        UserService.fetchUserData().then((res) => {
            setData(res.data);
            setBill(res.data.bill);
            setUsersPi(res.data.users_pi);
        });
    }, []);

    return (
        <NavBar location={props.history.location}>
            <Paper className={classes.content}>
                <div className={classes.toolbar} />
                    <Typography variant="h4" style={style}>
                        Інформація про користувача
                    </Typography>
                    <br />
                    <div style={styleBubble}>
                        {data.id === undefined && <div><LinearProgress /></div>}
                        {data.id !== undefined &&
                            (<Grid container spacing={1}>
                                <Grid item xs={12} sm={6}>
                                    <Container>
                                        <Table aria-label="Облікові дані">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell  colSpan={2}>
                                                        <h2><strong>Облікові дані</strong></h2>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Ім'я користувача:
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {data.id}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Депозит:
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {bill.deposit} грн.
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Кредит:
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {data.credit} грн.
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Статус:
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {data.disable === 1 ? <Chip label="Неактивно" color="secondary" variant="outlined" size="small" /> : <Chip label="Активно" color="primary" variant="outlined" size="small" />}
                                                        {data.suspended_until !== undefined && <Typography variant="caption" gutterBottom><br /> * Призупинено до {data.suspended_until}</Typography>}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Тарифний план:
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {data.tarif}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                        <Table aria-label="Загальні дані">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell  colSpan={2}>
                                                        <h2><strong>Загальні дані</strong></h2>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        ПІБ:
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {users_pi.fio}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Адреса:
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {users_pi.city ? ('г. ' + users_pi.city + ' ')  : ''}
                                                        {users_pi.address_street ? ('ул. ' + users_pi.address_street + ' ') : ''}
                                                        {users_pi.address_build ? ('д. ' + users_pi.address_build + ' ') : ''}
                                                        {users_pi.address_flat ? ('кв. ' + users_pi.address_flat + ' ') : ''}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Телефон:
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {users_pi.phone}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Container>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Container>
                                        <Table aria-label="Последняя Оплата">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell  colSpan={2}>
                                                        <h2><strong>Остання оплата</strong></h2>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Дата:
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {data.last_payment_date}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Сума:
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {data.last_payment_amount} грн.
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Container>
                                </Grid> 
                            </Grid>
                        )}
                    </div>
            </Paper>
        </NavBar>
    );

}

export default UserInfoComponent;
