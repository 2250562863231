import React, { useState, useEffect } from 'react';
import AuthService from "../../service/AuthService";
import UserService from "../../service/UserService";
import Typography from '@material-ui/core/Typography';
import NavBar from "../NavBar";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { styleBubble } from '../../styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';


const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3,2),
    margin: theme.spacing(1,1),
    backgroundColor: '#fffaf2',
    borderRadius: '1rem',
  },
  toolbar: theme.mixins.toolbar,
  form: {
    '& > *': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));

const style = {
    display: 'flex',
    justifyContent: 'left'
}

const userInfo = AuthService.getUserInfo();

const MegabonusComponent = (props) => {

    const [message, setMessage] = useState('');
    const [messageColor, setMessageColor] = useState('red');
    const [deposit, setDeposit] = useState(0);
    const [paid, setPaid] = useState(0);
    const [error, setError] = useState(0);
    const classes = useStyles();

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    useEffect(() => {
        UserService.megabonusCheck().then((res) => {
            if(res && res.status === 200){
                if (res.data.error === 0) {
                    setMessageColor('green');
                } else {
                    setError(1);
                }
                setMessage(res.data.message);
                setDeposit(res.data.megabonus_deposit);
            } else {
                //setMessageColor('red');
            }
        });
    }, []);


    const useMegabonusPayment = (e) => {
        const data = {uid: userInfo.uid};
        setMessage('');
        if (deposit > 0) {
            UserService.megabonusUse(data).then(result => {
                if(result && result.status === 200){
                    if (result.data.error === 0) {
                        setMessageColor('green');
                    } else {
                        setMessageColor('red');
                    }
                    setPaid(result.data.paid);
                    setDeposit(result.data.megabonus_deposit);
                    setMessage(result.data.message);
                } else {
                    setMessageColor('red');
                    setMessage('Виникла помилка, спробуйте пізніше');
                }
            })
            .then(response => {
                //console.log(response)
            })
            .catch(error => {
                //console.log(error.response)
                if (error.response) {
                    setMessageColor('red');
                    setMessage('Помилка поповнення, спробуйте пізніше');
                }
            });
        } else {
            setMessageColor('red');
            setMessage('На жаль, бонусний рахунок має нульовий баланс');
        }
    };


    return (
        <NavBar location={props.history.location}>
            <Paper className={classes.content}>
                <div className={classes.toolbar} />
                <Typography variant="h4" style={style}>
                    Бонусна програма
                </Typography>
                <br />
                <div style={styleBubble}>
                    <h2>
                        УВАГА! Умови акції діють тільки для абонентiв, яких було пiдключено з 01.05.2017, для багатоповерхових будівель у мiстi Лубни!
                    </h2>
                    <h3>
                        Для того, щоб отримати бонусні кошти при підключенні в сумі 2000 грн., необхідно:<br />
                        - Щоб рахунок був поповнений на суму не менше 650 грн.(декількома або одним платежем протягом користування);<br />
                        - На один рік можна використати тільки 500 бонусних коштів;<br />
                        - Стан рахунку не повинен заходити в мінус, а щомісячна оплата повинна бути обов'язково вчасною, у разі невчасно оплаченого рахунку всі бонусні кошти (до 2000 грн.) відразу згорають.<br />
                    </h3>
                    <h3>
                        * Програма лояльності і можливість призупиняти користування інтернетом на 7 днів кожного місяця залишаються незмінними.<br />
                        * Грошові кошти, переведені на основний рахунок по програмі лояьлності, не враховуються в необхідній сумі поповнення 650 грн.
                    </h3>
                    {message !== '' &&
                        (
                            <h3>
                                На Вашому бонусному рахунку {deposit} грн.
                            </h3>
                        )
                    }
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={9} align="center">
                            {message === '' && <CircularProgress />}
                            {message !== '' &&
                                (
                                    <Box color={messageColor}>
                                        <h3>{message}</h3>
                                    </Box>
                                )
                            }
                        </Grid>
                        <Grid item xs={12} sm={3} align="center">
                            {deposit > 0 && message !== '' && error === 0 && paid === 0 && (
                                <form  className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
                                    <Button variant="contained" color="primary" size="large" onClick={useMegabonusPayment}><CardGiftcardIcon />&nbsp;Використати</Button>
                                </form>
                            )}
                        </Grid> 
                    </Grid>
                </div>
            </Paper>
        </NavBar>
    );

}

export default MegabonusComponent;
