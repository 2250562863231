import React, { useEffect, useState } from 'react';
import UserService from "../../service/UserService";
import Typography from '@material-ui/core/Typography';
import NavBar from "../NavBar";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import PropTypes from 'prop-types';
import { styleBubble } from '../../styles';
import LinearProgress from '@material-ui/core/LinearProgress';


const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3,2),
    margin: theme.spacing(1,1),
    backgroundColor: '#fffaf2',
    borderRadius: '1rem',
  },
  toolbar: theme.mixins.toolbar,
}));

const style = {
    display: 'flex',
    justifyContent: 'left'
}

const PaginationTableComponent = (props) => {
    const { label, headCells, rows } = props;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    
    return (
        <div>
            <Table aria-label={label}>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={Object.keys(headCells).length}><h2><strong>{label}</strong></h2></TableCell>
                    </TableRow>
                    <TableRow>
                        {Object.keys(headCells).map((key, i) => 
                            <TableCell key={i} align={headCells[key]}><strong>{key}</strong></TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                            return (
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={row.id}
                                >
                                    <TableCell component="th" id={index} scope="row" padding="none">
                                        {row.date}
                                    </TableCell>
                                    <TableCell align="right">{row.sum} грн.</TableCell>
                                    <TableCell align="right">{row.last_deposit} грн.</TableCell>
                                </TableRow>
                            );
                        })}
                    {rows.length === 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} align="center" style={{ verticalAlign: 'top' }}>
                                <LinearProgress />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <TablePagination 
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage='' />
        </div>
    );
}

PaginationTableComponent.propTypes = {
  label: PropTypes.string.isRequired,
  headCells: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
};

const MoneyHistoryComponent = (props) => {

    const classes = useStyles();
    const [payments, setPayments] = useState([]);
    const [fees, setFees] = useState([]);

    useEffect(() => {
        UserService.fetchPayments().then((res) => {
            setPayments(res.data);
        });
        UserService.fetchFees().then((res) => {
            setFees(res.data);
        });
    }, []);

    return (
        <NavBar location={props.history.location}>
            <Paper className={classes.content}>
                <div className={classes.toolbar} />
                    <Typography variant="h4" style={style}>
                        Історія фінансових операцій
                    </Typography>
                    <br />
                    <div style={styleBubble}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <PaginationTableComponent 
                                    label="Поповнення"
                                    headCells={{'Дата': 'left', 'Сума': 'right', 'Депозит': 'right'}}
                                    rows={payments}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <PaginationTableComponent 
                                    label="Зняття"
                                    headCells={{'Дата': 'left', 'Сума': 'right', 'Депозит': 'right'}}
                                    rows={fees}
                                />
                            </Grid> 
                        </Grid>
                    </div>
            </Paper>
        </NavBar>
    );

}

export default MoneyHistoryComponent;
