import React, { useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';


const drawerWidth = 310;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  menuIcon: {
    marginRight: 14,
  },
  nested: {
    paddingLeft: theme.spacing(10),
  },
}));

const linkStyle = {
    textDecoration: 'none',
    color: 'black'
}

const NavBar = (props) => {
	//const { container, unreadMessages=[] } = props;
	const { container } = props;
    const classes = useStyles();
	const theme = useTheme();
  	const [mobileOpen, setMobileOpen] = useState(false);
    const [open, setOpen] = useState(true); // nested list item

	const handleDrawerToggle = () => {
    	setMobileOpen(!mobileOpen);
  	};

    const handleClick = () => {
        setOpen(!open);
    };

    const isItemSelected = (loc) => {
        return props.location.pathname === '/' + loc
    }
    /*
        <img alt="" style={styles.logoSimpleSm} src="/images/logo-simple-sm.png" />
    
                <ListItem button selected={isItemSelected('cards')} component={Link} key='cards' to="/cards">
                    <ListItemIcon><img alt="" className={classes.menuIcon} src="/images/payment-card1.png" /></ListItemIcon>
                    <ListItemText primary="Карты Пополнения" />
                </ListItem>
    */

    const drawer = (
        <div>
            <div className={classes.toolbar}>
                    <div style={styles.topName}>
                        <img alt="" style={styles.logoSimpleSm} src="/images/logo-mobile.png" />
                    </div>
            </div>
            <Divider />
           <List> 
                <ListItem button selected={isItemSelected('info')} component={Link} key='info' to="/info">
                    <ListItemIcon><img alt="" className={classes.menuIcon} src="/images/user1.png" /></ListItemIcon>
                    <ListItemText primary="Iнформацiя" />
                </ListItem>
                <ListItem button onClick={handleClick}>
                    <ListItemIcon><img alt="" className={classes.menuIcon} src="/images/money_operation1.png" /></ListItemIcon>
                    <ListItemText primary="Фінансові операції" />
					{open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button selected={isItemSelected('history')} component={Link} className={classes.nested} key="history" to="/history">
                            <ListItemText primary="Історія" />
                        </ListItem>
                        <ListItem button selected={isItemSelected('payment')} component={Link} className={classes.nested} key="payment" to='/payment'>
                            <ListItemText primary="Поповнення рахунку" />
                        </ListItem>
                    </List>
                </Collapse>
                <ListItem button selected={isItemSelected('trusted')} component={Link} key='trusted' to="/trusted">
                    <ListItemIcon><img alt="" className={classes.menuIcon} src="/images/loyal1.png" /></ListItemIcon>
                    <ListItemText primary="Довiрений платiж" />
                </ListItem>
                <ListItem button selected={isItemSelected('loyal')} component={Link} key='loyal' to="/loyal">
                    <ListItemIcon><img alt="" className={classes.menuIcon} src="/images/loyal.png" /></ListItemIcon>
                    <ListItemText primary="Програма лояльностi" />
                </ListItem>
                <ListItem button selected={isItemSelected('bonus')}  component={Link} key='bonus' to="/bonus">
                    <ListItemIcon><img alt="" className={classes.menuIcon} src="/images/bonus1.png" /></ListItemIcon>
                    <ListItemText primary="Бонусна програма" />
                </ListItem>
                <ListItem button selected={isItemSelected('support')} component={Link} key='support' to="/support">
                    <ListItemIcon><img alt="" className={classes.menuIcon} src="/images/call_help.png" /></ListItemIcon>
                    <ListItemText primary="Пiдтримка" />
                </ListItem>
                <ListItem button selected={isItemSelected('internet')} component={Link} key='internet' to="/internet">
                    <ListItemIcon><img alt="" className={classes.menuicon} src="/images/ipn_user_activate1.png" /></ListItemIcon>
                    <ListItemText primary="Iнтернет" />
                </ListItem>
                <a href="https://my.prosto.net/" style={linkStyle} target="_blank" rel="noopener noreferrer">
                    <ListItem button key='tv' to="https://my.prosto.net/">
                        <ListItemIcon><img alt="" className={classes.menuicon} src="/images/tv.png" /></ListItemIcon>
                        <ListItemText primary="Телебачення" />
                    </ListItem>
                </a>
            </List>
            <Divider />
            <List>
                <ListItem button component={Link} key='Logout' to="/">
                    <ListItemIcon><img alt="" className={classes.menuIcon} src="/images/logout1.png" /></ListItemIcon>
                    <ListItemText primary="Вихiд" />
                </ListItem>
            </List>
        </div>
    );

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar} style={styles.bgYellow}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h5" noWrap style={styles.topName}>
                        Мій Особистий Кабінет
                    </Typography>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                          paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
			{props.children}
        </div>
    )
}

const styles = {
    center: {
        display: 'flex',
        justifyContent: 'center'

    },
    notification: {
        display: 'flex',
        justifyContent: 'center',
        color: '#dc3545'
    },
    bgYellow: {
        background: '#ffc401',
    },
    logoSimpleSm: {
        //marginBottom: -7,
        //marginTop: 3
    },
    topName: {
        color: 'black',
        fontWeight: 600,
        textAlign: 'center',
        paddingTop: 5,
        //background: '#ffc401',
    },
}

export default NavBar;
