
var Enum = require('enum');

export var messageType = new Enum({
	'New': 0,
	'Reply': 1
});

export var messageStatus = {
    0: 'Відкрито',
    1: 'Не виконано і закрито',
    2: 'Виконано і закрито',
    3: 'В обробці',
    4: 'Нове повідомлення',
    5: 'Призупинення',
    6: 'Чекаємо на відповідь',
    7: 'Делеговано',
    8: 'Відповідальний'
};
