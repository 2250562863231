import React, { useState, useEffect } from 'react';
import AuthService from "../../service/AuthService";
import UserService from "../../service/UserService";
import Typography from '@material-ui/core/Typography';
import NavBar from "../NavBar";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Container from '@material-ui/core/Container';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { styleBubble } from '../../styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import LoyaltyIcon from '@material-ui/icons/Loyalty';


const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3,2),
    margin: theme.spacing(1,1),
    backgroundColor: '#fffaf2',
    borderRadius: '1rem',
  },
  toolbar: theme.mixins.toolbar,
  form: {
    '& > *': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));

const style = {
    display: 'flex',
    justifyContent: 'left'
}

const userInfo = AuthService.getUserInfo();

const LoyaltyProgramComponent = (props) => {

    const [message, setMessage] = useState('');
    const [messageColor, setMessageColor] = useState('red');
    const [deposit, setDeposit] = useState(0);

    const classes = useStyles();

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const loyaltyTableContent = {
        'Від 1 року до 2-х': '1%',
        'Від 2 рокiв до 3-х': '3%',
        'Від 3 рокiв до 4-х': '5%',
        'Більше 4-х років': '7%'
    }

    useEffect(() => {
        UserService.loyaltyPaymentCheck().then((res) => {
            if(res && res.status === 200){
                if (res.data.error === 0) {
                    setMessageColor('green');
                    setDeposit(res.data.bonus_deposit)
                } else {
                    setDeposit(0);
                }
                setMessage(res.data.message);
                //console.log('fetchUserData message', message)
            } else {
                setMessageColor('red');
            }
        });
    }, []);
    
    const useLoyaltyPayment = (e) => {
        setMessage('');
        const data = {uid: userInfo.uid};
        if (deposit > 0) {
            UserService.loyaltyPaymentUse(data).then(result => {
                if(result && result.status === 200){
                    if (result.data.error === 0) {
                        setMessageColor('green');
                        setDeposit(0)
                    } else {
                        setMessageColor('red');
                    }
                    setMessage(result.data.message);
                } else {
                    setMessageColor('red');
                    setMessage('Виникла помилка, спробуйте пізніше');
                }
            })
            .then(response => {
                //console.log(response)
            })
            .catch(error => {
                //console.log(error.response)
                if (error.response) {
                    setMessageColor('red');
                    setMessage('Помилка поповнення, спробуйте пізніше');
                }
            });
        } else {
            setMessageColor('red');
            setMessage('На жаль, бонусний рахунок має нульовий баланс');
        }
    };

    return (
        <NavBar location={props.history.location}>
            <Paper className={classes.content}>
                <div className={classes.toolbar} />
                    <Typography variant="h4" style={style}>
                        Програма лояльностi
                    </Typography>
                    <br />
                    <div style={styleBubble}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={9}>
                                <h3>
                                    1. Програма лояльності доступна всім абонентам компанії і автоматично активується на особистому абонентському рахунку при підключенні до мережі Iнтернет.
                                    <br />
                                    2. Кількість бонусних коштів розраховується в залежності від суми, яку ви будете оплачувати, та періоду користування інтернетом від "CONNECT".
                                    <br />
                                    3. Якщо Ви здійснюєте оплату кожного місяця, тоді бонуси також будуть нараховані 1 раз на місяць. Якщо Ви здійснюєте оплату єдиноразовим платежем за 12 місяців, тоді бонуси будуть нараховані одразу однією сумою.
                                    <br />
                                    4. Необхідною умовою накопичення бонусних коштів є <strong>регулярне внесення абонентської плати</strong>, в іншому випадку відсоток бонусу знизиться з набутого до <strong>початкового - 0%</strong>.
                                    <br />
                                    5. Звертаємо увагу, що бонуси не будуть нараховуватися при відключенні абонента від нашої мережі. Також, для накопичення бонусів, рахунок абонента не повинен бути нижчим, ніж 0,01 грн.
                                    <br />
                                    6. Компанія "CONNECT" залишає за собою усі права, в т.ч. і на зміни умов даної програми лояльності.
                                    <br />
                                </h3>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                               <Container component={Paper}>
                                    <Table aria-label="table" size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><strong>Лояльний стаж</strong></TableCell>
                                                <TableCell align="right"><strong>Размiр бонуса</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.keys(loyaltyTableContent).map((keyName, i) => (
                                                <TableRow key={i}>
                                                    <TableCell component="th" scope="row">
                                                        {keyName}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align="right">
                                                        {loyaltyTableContent[keyName]}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow key='actions'>
                                                <TableCell component="th" scope="row">
                                                    <h3>Доступно на бонусному рахунку:</h3>
                                                </TableCell>
                                                <TableCell component="th" scope="row" align="right">
                                                    {message === '' && <CircularProgress />}
                                                    {message !== '' && <h3>{deposit} грн.</h3>}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Container>
                            </Grid> 
                            <Grid item xs={12} sm={9} align="center">
                                {message === '' && <CircularProgress />}
                                {message !== '' &&
                                    (
                                        <Box color={messageColor}>
                                            <h3>{message}</h3>
                                        </Box>
                                    )
                                }
                            </Grid> 
                            <Grid item xs={12} sm={3} align="center">
                                {deposit > 0 && message !== '' && (
                                    <form  className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
                                        <Button variant="contained" color="primary" size="large" onClick={useLoyaltyPayment}><LoyaltyIcon />&nbsp;Використати</Button>
                                    </form>
                                )}
                            </Grid> 
                        </Grid>
                    </div>
            </Paper>
        </NavBar>
    );

}

export default LoyaltyProgramComponent;
