import React, { useState, useEffect } from 'react';
import AuthService from "../../service/AuthService";
import UserService from "../../service/UserService";
import Typography from '@material-ui/core/Typography';
import NavBar from "../NavBar";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { styleBubble } from '../../styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3,2),
    margin: theme.spacing(1,1),
    backgroundColor: '#fffaf2',
    borderRadius: '1rem',
  },
  toolbar: theme.mixins.toolbar,
  form: {
    '& > *': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  disable: {
      backgroundColor: theme.palette.error.main,
  },
  enable: {
      backgroundColor: theme.palette.primary.main,
  }
}));

const style = {
    display: 'flex',
    justifyContent: 'left'
}

const userInfo = AuthService.getUserInfo();


const InternetSuspendComponent = (props) => {

    const [message, setMessage] = useState('');
    const [isUsed, setIsUsed] = useState(0);
    const [suspendId, setSuspendId] = useState(0);
    const [messageColor, setMessageColor] = useState('red');
    const classes = useStyles();

    useEffect(() => {
        UserService.internetSuspendCheck().then((result) => {
            if (result.data.is_used === 0) setMessageColor('green'); else setMessageColor('red');
            if (result.data.suspend_id !== undefined) setSuspendId(result.data.suspend_id);
            setMessage(result.data.message);
            setIsUsed(result.data.is_used);
        });
    }, []);
    
    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const suspendInternet = (e) => {
        setMessage('');
        const data = {uid: userInfo.uid};
        UserService.internetSuspendUse(data).then(result => {
            if(result && result.status === 200){
                setMessage(result.data.message);
                if (result.data.is_used === 0) setMessageColor('green'); else setMessageColor('red');
            } else {
                setMessageColor('red');
            }
            setMessage(result.data.message);
            setIsUsed(result.data.is_used);
        })
        .then(response => {
            //console.log(response)
        })
        .catch(error => {
            //console.log(error.response)
            if (error.response) {
                setMessageColor('red');
                setMessage('Виникла помилка, спробуйте пiзнiше');
            }
        });
    };
    
    const unSuspendInternet = (e) => {
        setMessage('');
        const data = {uid: userInfo.uid, suspend_id: suspendId};
        UserService.internetUnSuspendUse(data).then(result => {
            if(result && result.status === 200){
                setMessage(result.data.message);
                setSuspendId(0);
                setIsUsed(result.data.is_used);
                setMessageColor('green');
            } else {
                setMessageColor('red');
            }
            setMessage(result.data.message);
        })
        .then(response => {
            //console.log(response)
        })
        .catch(error => {
            //console.log(error.response)
            if (error.response) {
                setMessageColor('red');
                setMessage('Виникла помилка, спробуйте пiзнiше');
            }
        });
    };


    return (
        <NavBar location={props.history.location}>
            <Paper className={classes.content}>
                <div className={classes.toolbar} />
                <Typography variant="h4" style={style}>
                    Призупинення послуги Iнтернет
                </Typography>
                <br />
                <div style={styleBubble}>
                    <h3>
                        Призупинення Iнтернету можливе на 7 днів.
                        <br />
                        Дана послуга доступна лише раз на місяць. Якщо Ви скористалися паузою та через кілька днів відновили користування Iнтернетом, то послуга вважається використаною в цьому місяці. 
                        <br />
                        Повторно Ви зможете призупинити зняття коштів з особистого рахунку лише з 1-го числа наступного місяця. 
                        <br />
                        <br />
                        Якщо з якихось причин Вам необхідне призупинення Iнтернету на більш тривалий термін, ніж один тиждень, можете звернутися на технічну підтримку за додатковою інформацією: 0668857000/0988857000 (режим callback).
                    </h3>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={9}>
                            <Box color={messageColor} align="center">
                                {message === '' ?
                                    (<div align="center" style={{ paddingTop: '7px' }}>
                                        <CircularProgress />
                                    </div>)
                                    :
                                    <h3>{message}</h3>
                                }
                            </Box>
						</Grid> 
                        <Grid item xs={12} sm={3} align="center">
                            {message !== '' && isUsed === 0 && (
                                <form  className={classes.form} noValidate autoComplete="off">
                                    <Button 
                                        variant="contained" 
                                        color="secondary"
                                        onClick={suspendInternet}
                                        startIcon={<PauseCircleOutlineIcon />}
                                    >
                                        Призупинити
                                    </Button>
                                </form>
                            )}
                            {message !== '' && suspendId !== 0 && (
                                <form  className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
                                    <Button 
                                        variant="contained" 
                                        color="primary"
                                        onClick={unSuspendInternet}
                                        startIcon={<PlayCircleOutlineIcon />}
                                    >
                                       Активувати
                                    </Button>
                                </form>
                            )}
                        </Grid> 
                    </Grid>
                </div>
            </Paper>
        </NavBar>
    );

}

export default InternetSuspendComponent;
