import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import React from "react";
import LoginComponent from "./user/LoginComponent";
import UserInfoComponent from "./user/UserInfoComponent";
import MoneyHistoryComponent from "./user/MoneyHistoryComponent";
import PaymentComponent from "./user/PaymentComponent";
import TrustedPaymentComponent from "./user/TrustedPaymentComponent";
import LoyaltyProgramComponent from "./user/LoyaltyProgramComponent";
import MegabonusComponent from "./user/MegabonusComponent";
import SupportComponent from "./user/SupportComponent";
import SupportThreadComponent from "./user/SupportThreadComponent";
import InternetSuspendComponent from "./user/InternetSuspendComponent";
import PrivateRoute from "./PrivateRoute.jsx";

const AppRouter = () => {
    return (
            <Router>
                    <Switch>
                        <Route path="/" exact component={LoginComponent} />
                        <Route path="/login" exact component={LoginComponent} />
						<PrivateRoute path="/info" component={UserInfoComponent} />
						<PrivateRoute path="/history" component={MoneyHistoryComponent} />
						<PrivateRoute path="/payment" component={PaymentComponent} />
						<PrivateRoute path="/payment" component={PaymentComponent} />
						<PrivateRoute path="/trusted" component={TrustedPaymentComponent} />
						<PrivateRoute path="/loyal" component={LoyaltyProgramComponent} />
						<PrivateRoute path="/bonus" component={MegabonusComponent} />
						<PrivateRoute path="/support/thread/:message_id" component={SupportThreadComponent} />
						<PrivateRoute path="/support/thread/" component={SupportThreadComponent} />
						<PrivateRoute path="/support" component={SupportComponent} />
						<PrivateRoute path="/internet" component={InternetSuspendComponent} />
                    </Switch>
            </Router>
    )
}

export default AppRouter;
