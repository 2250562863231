import React, { useState, useEffect } from 'react';
import AuthService from "../../service/AuthService";
import UserService from "../../service/UserService";
//import { readableFileSize } from '../../helpers'
import Typography from '@material-ui/core/Typography';
import NavBar from "../NavBar";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Linkify from 'react-linkify';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import TelegramIcon from '@material-ui/icons/Telegram';
import Link from '@material-ui/core/Link';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { messageType } from '../../enums';
import { styleBubble } from '../../styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3,2),
    margin: theme.spacing(1,1),
    backgroundColor: '#fffaf2',
	borderRadius: '1rem',
  },
  toolbar: theme.mixins.toolbar,
  form: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
}));

const style = {
    display: 'flex',
    justifyContent: 'left'
}

const styleBubbleBefore = {
	content: '',
	position: 'absolute',
	width: 0,
	height: 0,
	bottom: '100%',
	left: '1.5em',
	border: '.75rem solid transparent',
	borderTop: 'none',
	borderBottomColor: '#fff',
	filter: 'drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1))',
}

function readableFileSize(size) {
    var units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    var i = 0;
    while(size >= 1024) {
        size /= 1024;
        ++i;
    }
    return parseFloat(size).toFixed(1) + ' ' + units[i];
}

const userInfo = AuthService.getUserInfo();
    
const MessageBoxComponent = ({reply}, key) => {
    return (
        <div style={styleBubble} key={key + 3000}>
            <div>
                <Linkify>
                    {reply.text.split('\n').map((item, i) => <div key={i + 1000}>{item}</div>)}
                </Linkify>
            </div>
            <AttachmentDownloadComponent attachments={reply.attachments} />
            <div style={styleBubbleBefore} />
        </div>
    );
}

const AttachmentDownloadComponent = ({attachments=[]}) => {
    const handleDownload = (e, attachment) => {
        e.preventDefault();
        const data = {attachment_id: attachment.id, filename: attachment.filename, uid: userInfo.uid}
        return UserService.getAttachment(data);
    }

    return (

        <div>
            {attachments.length > 0 && (<Box color='text.secondary'><br />Вложение:</Box>)}
            {Object.keys(attachments).map((keyObj, i) => {
                return (
                    <span key={keyObj}>
                        <Link href="#" onClick={(e) => {handleDownload(e, attachments[keyObj])}}>
                            {attachments[keyObj].filename}
                        </Link> 
                        &nbsp;({readableFileSize(attachments[keyObj].content_size)})&nbsp;
                    </span>
                )
            })}
        </div>
    )
}
    

const NewMessageComponent = ({data, handleRefresh}) => {
   
    const [message, setMessage] = useState('');
    const [messageColor, setMessageColor] = useState('red');
    const [subject, setSubject] = useState('');
    const [text, setText] = useState('');
    const [files, setFiles] = useState([]);
    const [textError, setTextError] = useState(false);
    const [subjectError, setSubjectError] = useState(false);
    const [filesError, setFilesError] = useState(false);
    const [pressed, setPressed] = useState(false);

    const type = data.type
    const messageId = data.messageId
    const classes = useStyles();

    let formData = new FormData();
   
    useEffect(() => {
        return () => { 
            setMessage('');
            setSubject('');
            setText('');
            setFiles([]);
        }
    }, []);

    const send = (e) => {
        e.preventDefault();
        
        if (type.value === messageType.New.value) {
            if (subject === '') { setSubjectError(true); } else { setSubjectError(false); }
            if (text === '') { setTextError(true); } else { setTextError(false); }
            if (subject === '' || text === '') return false;
        } else if (type.value === messageType.Reply.value) {
            if (text === '') { setTextError(true); return false; } else { setTextError(false); }
        }
       
        setPressed(true);

        const sendData = {login: userInfo.login, uid: userInfo.uid, subject: subject, text: text, type: type.value, message_id: messageId, files: files}
        for (const [key, value] of Object.entries(sendData)) {
            formData.append(key, value);
        }
        for (let i = 0; i < files.length; i++) {
            formData.append('file'+i, files[i])
        }
        let res = null;
        UserService.createMessage(formData).then(result => {
            if(result && result.status === 200){
                setSubject('');
                setText('');
                setFiles([]);
                setMessageColor('green');
                res = result;
            }
            setMessage(result.data.message);
        })
        .then(response => {
            if (type.value === messageType.New.value) { 
                if (res.data.message_id !== undefined) {
                    //setTimeout(() => handleRefresh(res.data.message_id), 3000);
                    handleRefresh(res.data.message_id);
                }
            } else { handleRefresh(); }
            setPressed(false);
            //console.log('response:', response)
        })
        .catch(error => {
            //console.log('error respone:', error)
        });
    }

    const onChangeSubject = (e) => {
        setSubject(e.target.value);
        if (e.currentTarget.value.length > 0) { setSubjectError(false) } else { setSubjectError(true) }
    }

    const onChangeText = (e) => {
        setText(e.target.value);
        if (e.currentTarget.value.length > 0) { setTextError(false) } else { setTextError(true) }
    }

    const onChangeAttachments = (e) => {
        const fileList = e.target.files
        const maxSizeOfAttachment = 1024 * 1024 * 5;
        //console.log('target.files', fileList)
        /*
        if (fileList.length > 3) {
            setMessage('Вы превысили максимальное количество файлов в одном cообщении (3 файла)')
            setFilesError(true);
            return;
        }
        */
        Object.keys(e.target.files).map((key, i) => {
            if (e.target.files[key].size > maxSizeOfAttachment) {
                setMessage('Ви перевищили допустимий розмір файлу (5МБ максимум)')
                setFilesError(true);
                return false;
            }
            return true;
        });
        if (!filesError) {
            let preparedFilesList = [];
            for (let i = 0; i < fileList.length; i++) {
                preparedFilesList.push(fileList[i])
            }
            setFiles(preparedFilesList);
        }
    }

    const onClickAttachments = (e) => {
        setMessage('');
        setFilesError(false);
        for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i])
        }
        //console.log('data', formData)
    }
    
    return (
        <div style={styleBubble}>
            <form className={classes.form} autoComplete="off">
                {type.value === messageType.New.value 
                    ? 
                    <TextField id="subject" required label="Тема" size="small" variant="outlined" 
                        inputProps={{ maxLength: 200, }}
                        value={subject} 
                        onChange={e => onChangeSubject(e)}
                        error={subjectError}
                        helperText={subjectError ? 'Введiть тему' : ''}
                    /> 
                    : ''
                }
                <TextField
                    inputProps={{ maxLength: 2000, }}
                    required
                    id="message"
                    label="Повiдомлення"
                    multiline
                    rows="4"
                    variant="outlined"
                    value={text} 
                    onChange={e => onChangeText(e)}
                    error={textError}
                    helperText={textError ? 'Введiть повiдомлення' : ''}
                />
                <br />
                <input
                    accept="images/*"
                    className={classes.input}
                    style={{ display: 'none' }}
                    id="attachments"
                    //multiple
                    type="file"
                    onChange={onChangeAttachments}
                />
                <label htmlFor="attachments">
                    <Button variant="text" component="span" size="small" className={classes.button} onClick={onClickAttachments}>
                        <AttachFileIcon />&nbsp;Вкласти файл
                    </Button>
                </label>
                {files.length > 0 && (<span>Вложение:</span>)}
                    {Object.keys(files).map((key, i) => {
                        return (
                            <span key={key + 6000}>
                                {files[key].name}
                            </span>
                        );
                    })}
                <br />
                <br />
                <label>
                    {pressed && <CircularProgress />}
                    {!pressed &&
                        (
                            <Button variant="contained" component="span" color="primary" className={classes.button} onClick={send}>
                                <TelegramIcon />&nbsp;{type.value === messageType.New.value ? <span>Створити</span> : <span>Вiдповiсти</span>}
                            </Button>
                        )
                    }
                </label>
                <Box color={messageColor}>
                    <h3>{message}</h3>
                </Box>
            </form>
        </div>
    );
}

const SupportThreadComponent = (props) => {

    const [message, setMessage] = useState({});
    const [replies, setReplies] = useState([]);
    const [messageId, setMessageId] = useState(props.match.params.message_id);
    const replyData = {type: messageType.Reply, messageId: messageId}
    const createData = {type: messageType.New, messageId: null}
    const classes = useStyles();
    
    useEffect(() => {
        if (messageId !== undefined) {
            UserService.fetchMessage(messageId).then((res) => {
                setMessage(res.data);
                setReplies(res.data.replies);
            });
        }
    }, [messageId]);
    
    const handleRefreshReplyMessage = () => {
        UserService.fetchMessage(messageId).then((res) => {
            setReplies(res.data.replies);
        });
    };
    
    const handleRefreshNewMessage = (message_id) => {
        setMessageId(message_id);
        props.history.push('/support/thread/' + message_id + '/');
    };
    
    return (
        <NavBar location={props.history.location}>
            <Paper className={classes.content}>
                <div className={classes.toolbar} />
                <Typography variant="h4" style={style}>
                    Технічна підтримка
                </Typography>
                <br />
                <Button variant="text" component="span" size="small" className={classes.button}>
                    <KeyboardBackspaceIcon />
                    <a href="/support" style={{ textDecoration: 'none' }}>    
                        &nbsp;Назад до списку повідомлень
                    </a>
                </Button>
                {messageId ? (
                        <div>
                            <br />
                        <div style={styleBubble}>
                            {message.message === undefined && <div><LinearProgress /></div>}
                            {message.message !== undefined &&
                                (<div>
                                    <Box component="div">
                                        <strong>Автор:</strong> {message.aid === 3 ? `${userInfo.login}` : 'Технічна підтримка'}
                                        <br />
                                        <strong>Тема:</strong> {message.subject}
                                        <br />
                                        <strong>Створено:</strong> {message.date}
                                    </Box>
                                    <hr />
                                    <Linkify>
                                        {'message' in message && (
                                            message.message.split('\n').map((item, i) => <div key={i + 2000}>{item}</div>)
                                        )}
                                    </Linkify>
                                    <AttachmentDownloadComponent attachments={message.attachments} />
                                </div>)
                            }
                        </div>
                        
                        {Object.keys(replies).map((key, i) => {
                            return (
                                <div key={i + 4000}>
                                {replies[key].text !== '' && (
                                    <div>
                                        <div style={{padding: '15px'}}>
                                            <strong>
                                                {replies[key].aid === 0 ? `${userInfo.login}` : 'Технічна підтримка'} 
                                            </strong>
                                            &nbsp;({replies[key].datetime}):
                                        </div>
                                        <MessageBoxComponent reply={replies[key]} key={i} />
                                    </div>
                                )}
                                </div>
                            );
                        })}
                        <br />
                        {message.message !== undefined &&
                            <NewMessageComponent data={replyData} handleRefresh={handleRefreshReplyMessage} />
                        }
                        </div>
                    )
                :
                    (
                        <div>
                            <h2>Нове повідомлення</h2>
                            <NewMessageComponent data={createData} handleRefresh={handleRefreshNewMessage} />
                        </div>
                    )
                }
            </Paper>
        </NavBar>
    );

}

export default SupportThreadComponent;
