import React, { useState, useEffect } from 'react';
import AuthService from "../../service/AuthService";
import UserService from "../../service/UserService";
import Typography from '@material-ui/core/Typography';
import NavBar from "../NavBar";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { styleBubble } from '../../styles';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3,2),
    margin: theme.spacing(1,1),
    backgroundColor: '#fffaf2',
    borderRadius: '1rem',
  },
  toolbar: theme.mixins.toolbar,
  form: {
    '& > *': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));

const style = {
    display: 'flex',
    justifyContent: 'left'
}

const styleCircle = {
    display: 'inline-block',
    paddingTop: '7px'
}

//const link = "https://my-payments.privatbank.ua/mypayments/customauth/identification/fp/static?staticToken=488cb83e19dd514871afdf029f0b2a72pgn27sza";
//const qrCodeLink = (userInfo !== null) ? "http://qrcoder.ru/code/?EK_billidentifier_" + userInfo.login + "_3190953&4&0" : "";

//const userInfo = AuthService.getUserInfo();

const PaymentComponent = (props) => {

    const [error, setError] = useState(0);
    const [message, setMessage] = useState('');
    const [pressed, setPressed] = useState(false);
    const [messageColor, setMessageColor] = useState('red');
    const [series, setSeries] = useState('');
    const [pin, setPin] = useState('');
    const [seriesError, setSeriesError] = useState(false);
    const [pinError, setPinError] = useState(false);
    const [payLink, setPayLink] = useState(false);
    const [qrCodeLink, setQrCodeLink] = useState(false);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const classes = useStyles();

    useEffect(() => {
        UserService.paymentData().then((res) => {
            if(res && res.status === 200){
                if (res.data.error === 0) {
                    setPayLink(res.data.pay_link);
                    setQrCodeLink(res.data.qrcode_link)
                } else {
                    setError(1);
                }
                setMessage(res.data.message);
            } else {
                //setMessageColor('red');
            }
        });
    }, []);

    const cardPayment = (e) => {
        e.preventDefault();

        if (series === '') { setSeriesError(true); } else { setSeriesError(false); }
        if (pin === '') { setPinError(true); } else { setPinError(false); }

        const data = {login: userInfo.login, uid: userInfo.uid, series: series, pin: pin};
        if (series && pin) {
            setMessage('');
            setPressed(true);
            UserService.cardPayment(data).then(result => {
                if(result && result.status === 200){
                    if (result.data.paid && result.data.paid === 1) {
                        setMessageColor('green');
                        setSeries('');
                        setPin('');
                    } else {
                        setMessageColor('red');
                    }
                    setMessage(result.data.message);
                    setPressed(false);
                } else {
                    setMessageColor('red');
                    setMessage('Возникла ошибка, попробуйте позже');
                    setPressed(false);
                }
            })
            .then(response => {
                //console.log(response)
            })
            .catch(error => {
                //console.log(error.response)
                if (error.response) {
                    setMessageColor('red');
                    setMessage('Ошибка пополнения, попробуйте позже');
                    setPressed(false);
                }
            });
        }
    };

    const onChangeSeries = (e) => {
        setSeries(e.target.value);
        if (e.currentTarget.value.length > 0) { setSeriesError(false) } else { setSeriesError(true) }
    }
    
    const onChangePin = (e) => {
        setPin(e.target.value);
        if (e.currentTarget.value.length > 0) { setPinError(false) } else { setPinError(true) }
    }

    return (
        <NavBar location={props.history.location}>
            <Paper className={classes.content}>
                <div className={classes.toolbar} />
                    <Typography variant="h4" style={style}>
                        Поповнення рахунку
                    </Typography>
                    <br />
                    <div style={styleBubble}>
                        {error === 0 && qrCodeLink === false && payLink === false &&
                            <h3>{message}</h3>
                        }
                        {error === 0 && qrCodeLink !== false && payLink !== false &&
                            (<Grid container spacing={1}>
                                <Grid item xs={12} sm={6}>
                                    <h3>
                                        Поповнити рахунок використовуючи Visa/Mastercard:
                                    </h3>
                                        <Link href={payLink}  color="inherit" rel="noopener" style={{ textDecoration: 'none' }} target="_self">
                                            <Button variant="contained" color="primary" className={classes.button}>
                                                <AccountBalanceIcon fontSize="small" />&nbsp;Поповнити
                                            </Button>
                                        </Link>
                                    <br />
                                    <br />
                                    <h3>
                                        Поповнити рахунок за допомогою карти поповнення Connect:
                                    </h3>
                                    <form  className={classes.form} noValidate autoComplete="off">
                                        <TextField
                                            required
                                            id="series"
                                            value={series}
                                            onChange={e => onChangeSeries(e)}
                                            error={seriesError}
                                            helperText={seriesError ? 'Введiть Серiю' : ''}
                                            label="Серiя"
                                            disabled={pressed}
                                            variant="outlined"
                                        />
                                        <TextField 
                                            required
                                            id="pin"
                                            value={pin}
                                            onChange={e => onChangePin(e)}
                                            error={pinError}
                                            helperText={pinError ? 'Введiть PIN-код' : ''}
                                            label="PIN-код"
                                            disabled={pressed}
                                            variant="filled" 
                                        />
                                        {message === '' ?
                                            (<div align="center" style={styleCircle}>
                                                <CircularProgress />
                                            </div>)
                                            :
                                            <Button variant="contained" color="primary" size="large" onClick={cardPayment}><CreditCardIcon />&nbsp;Поповнити</Button>
                                        }
                                    </form>
                                    <Box color={messageColor}>
                                        <h3>{message}</h3>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <h3>
                                        QR код для поповнення рахунку у додатку Приват24 для мобільних пристроїв:
                                    </h3>
                                    <img src={qrCodeLink} alt="QR Code" />
                                </Grid> 
                            </Grid>)
                        }
                    </div>
            </Paper>
        </NavBar>
    );

}

export default PaymentComponent;
