import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import UserService from "../../service/UserService";
import Typography from '@material-ui/core/Typography';
import NavBar from "../NavBar";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import Badge from '@material-ui/core/Badge';
import { messageStatus } from '../../enums';
import { styleBubble } from '../../styles';
import LinearProgress from '@material-ui/core/LinearProgress';


const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3,2),
    margin: theme.spacing(1,1),
    backgroundColor: '#fffaf2',
    borderRadius: '1rem', 
  },
  toolbar: theme.mixins.toolbar,
}));

const style = {
    display: 'flex',
    justifyContent: 'left'
}

const linkStyle = {
    textDecoration: 'none',
    color: 'black'
}

const PaginationTableComponent = (props) => {
    const { label, headCells, rows, unread=[] } = props;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const classes = useStyles();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    
    return (
        <div style={styleBubble}>
            <Table aria-label={label}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <h2><strong>{label}</strong></h2>
                        </TableCell>
						<TableCell align="right" colSpan={(Object.keys(headCells).length-1)}>
                            <Button variant="contained" color="primary" component={Link} to={`/support/thread/`} className={classes.button}>
                               <CreateIcon />&nbsp;Написати
                            </Button>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        {Object.keys(headCells).map((key, i) => 
                            <TableCell key={i} align={headCells[key]}><strong>{key}</strong></TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                            return (
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={row.id}
                                >
                                    <TableCell component="th" id={index} scope="row" padding="none">
                                        <Link style={linkStyle} to={`/support/thread/${row.id}`}>
                                            {row.date}
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        <Link style={linkStyle} to={`/support/thread/${row.id}`}>
                                            {row.subject ? <span>{row.subject}</span> : <span>Без Темы</span>}&nbsp;&nbsp;&nbsp;&nbsp;
                                            {unread.includes(row.id) ? 
                                                (<Badge badgeContent="updated" color="error" fontSize="small">
                                                    &nbsp;
                                                 </Badge>)
                                            :
                                                ''
                                            }
                                        </Link>
                                    </TableCell>
                                    <TableCell align="right">{messageStatus[row.state]}</TableCell>
                                </TableRow>
                            );
                        })}
                    {rows.length === 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} align="center" style={{ verticalAlign: 'top' }}>
                                <LinearProgress />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <TablePagination 
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage='' />
        </div>
    );
}

PaginationTableComponent.propTypes = {
  label: PropTypes.string.isRequired,
  headCells: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
};

const SupportComponent = (props) => {

    const classes = useStyles();
    const [messages, setMessages] = useState([]);
    const [unread, SetUnread] = useState([]);

    useEffect(() => {
        UserService.fetchMessages().then((res) => {
            setMessages(res.data.data);
            SetUnread(res.data.new)
        });
    }, []);

    return (
        <NavBar location={props.history.location} unreadMessages={unread} >
            <Paper className={classes.content}>
                <div className={classes.toolbar} />
                    <Typography variant="h4" style={style}>
                        Технічна підтримка
                    </Typography>
                    <br />
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                            <PaginationTableComponent 
                                label="Повідомлення"
                                headCells={{'Дата': 'left', 'Тема': 'left', 'Статус': 'right'}}
                                rows={messages}
                                unread={unread}
                            />
                        </Grid>
                    </Grid>
            </Paper>
        </NavBar>
    );

}

export default SupportComponent;
