import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import AuthService from '../../service/AuthService';
import CircularProgress from '@material-ui/core/CircularProgress';
import Linkify from 'react-linkify';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {isMobile} from 'react-device-detect';
import InfoIcon from '@material-ui/icons/Info';


class LoginComponent extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            login: '',
            password: '',
            message: '',
            pressed: false,
        }
        this.login = this.login.bind(this);
    }

    componentDidMount() {
        localStorage.clear();
    }

    login = (e) => {
        e.preventDefault();
        const credentials = {login: this.state.login, password: this.state.password};
        if (this.state.login && this.state.password) {
            this.setState({pressed: true});
            AuthService.login(credentials).then(result => {
                if(result && result.status === 200){
                    if (result.data?.data?.utg_redirect_url) {
                        console.log('UTG LOGIN', result.data?.data?.utg_redirect_url)
                        window.location.assign(result.data?.data?.utg_redirect_url);
                    } else {
                        localStorage.setItem("userInfo", JSON.stringify(result.data.data));
                        this.props.history.push('/info');
                    }
                } else {
                    this.setState({message: result.data.message});
                    this.setState({pressed: false});
                }
            })
            .then(response => {
                //console.log(response)
            })
            .catch(error => {
                this.setState({pressed: false});
                //console.log(error.response)
                if (error.response) {
                    this.setState({message: error.response.data.message});
                }
            });
        } else {
            this.setState({message: 'Введіть Ім\'я користувача та Пароль'});
        }
    };

    onChange = (e) => this.setState({ [e.target.name]: e.target.value });

    isMobileDevice = () => {
        return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    };

    render() {
        return(
            <React.Fragment>
                <AppBar position="static" style={styles.bgYellow}>
                    <Toolbar style={styles.center}>
                        <Typography variant="h6">
                            <img src="/images/logo-abills.png" alt="" className="img-responsive" />

                        </Typography>
                    </Toolbar>
                </AppBar>
                <Container maxWidth="sm">
                    <br /><br />
                    <Typography variant="h4" style={styles.center}>Особистий Кабінет</Typography>
                    <form>
                        <Typography variant="h6" style={styles.notification}>
                            <Linkify>
                                <div>{this.state.message}</div>
                            </Linkify>
                        </Typography>
                        <TextField 
                            type="text" 
                            label="Ім'я користувача" 
                            fullWidth 
                            margin="normal" 
                            name="login" 
                            value={this.state.login} 
                            onChange={this.onChange}
                            disabled={this.state.pressed}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                  this.login(e);
                                }
                            }}
                        />
                        <TextField 
                            type="password" 
                            label="Пароль" 
                            fullWidth 
                            margin="normal" 
                            name="password" 
                            value={this.state.password} 
                            onChange={this.onChange}
                            disabled={this.state.pressed}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                  this.login(e);
                                }
                            }}
                        />
                        <br /><br />
                        <div align="center">
                        {this.state.pressed && <CircularProgress />}
                        {!this.state.pressed &&
                        <Button variant="contained" color="default" onClick={this.login}>Увiйти</Button>
                        }
                        </div>
                    </form>
                    <br />
                </Container>
                {!isMobile &&
                <Container>
                    <br />
                    <br />
                    <Carousel autoPlay interval={5000} infiniteLoop showThumbs={false} showStatus={false}>
                        <div>
                            <a target="blank" href="https://www.organica.net.ua/" style={{display: 'block'}}>
                                <img src="images/slider/organic.jpg" alt="" />
                            </a>
                        </div>
                        <div>
                            <a target="blank" href="https://www.organica.net.ua/" style={{display: 'block'}}>
                                <img src="images/slider/2.jpg" alt="" />
                            </a>
                        </div>
                    </Carousel>
                </Container>
                }
            </React.Fragment>
        )
    }

}

/*
                        <div>
                            <img src="images/slider/connect-1.jpg" alt="" />
                        </div>
                        <div>
                            <img src="images/slider/connect-2.jpg" alt="" />
                        </div>
                        <div>
                            <img src="images/slider/security.jpg" alt="" />
                        </div>

 */

const styles = {
    center: {
        display: 'flex',
        justifyContent: 'center'

    },
    notification: {
        display: 'flex',
        justifyContent: 'center',
        color: '#dc3545'
    },
    bgYellow: {
        //background: '#ffc0713b',
        background: '#ffc401',
    },
	info: {
        background: '#dcdcdc3b',
        align: 'center',
        color: '#000',
		//borderRadius: '1rem',
        padding: '13px',
	},
    absolute: {
        position: 'absolute',
    },
    infoHeader: {
        marginLeft: '28px',
    }
}


export default LoginComponent;
