import React, { useState, useEffect } from 'react';
import AuthService from "../../service/AuthService";
import UserService from "../../service/UserService";
import Typography from '@material-ui/core/Typography';
import NavBar from "../NavBar";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { styleBubble } from '../../styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import CreditCardIcon from '@material-ui/icons/CreditCard';

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3,2),
    margin: theme.spacing(1,1),
    backgroundColor: '#fffaf2',
    borderRadius: '1rem',
  },
  toolbar: theme.mixins.toolbar,
  form: {
    '& > *': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));

const style = {
    display: 'flex',
    justifyContent: 'left'
}

const userInfo = AuthService.getUserInfo();

const TrustedPaymentComponent = (props) => {

    const [message, setMessage] = useState('');
    const [messageColor, setMessageColor] = useState('red');
    const [isUsed, setIsUsed] = useState(0);
    const classes = useStyles();

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    useEffect(() => {
        UserService.trustedPaymentCheck().then((res) => {
            //console.log('fetchUserData', res.data)
            setMessage(res.data.message);
            if (res.data.is_used === 0) {
                setMessageColor('green');
            } else {
                setIsUsed(1);            
            }
        });
    }, []);
    
    const useTrustedPayment = (e) => {
        setMessage('');
        const data = {uid: userInfo.uid};
        UserService.trustedPaymentUse(data).then(result => {
            if(result && result.status === 200){
                if (result.data.paid && result.data.paid === 1) {
                    setMessageColor('green');
                    setIsUsed(1)
                } else {
                    setMessageColor('red');
                }
                setMessage(result.data.message);
            } else {
                setMessageColor('red');
                setMessage('Возникла ошибка, попробуйте позже');
            }
        })
        .then(response => {
            //console.log(response)
        })
        .catch(error => {
            //console.log(error.response)
            if (error.response) {
                setMessageColor('red');
                setMessage('Ошибка пополнения, попробуйте позже');
            }
        });
    };

    return (
        <NavBar location={props.history.location}>
            <Paper className={classes.content}>
                <div className={classes.toolbar} />
                    <Typography variant="h4" style={style}>
                        Довірений платіж
                    </Typography>
                    <br />
                    <div style={styleBubble}>
                        <h2>
                            Забули вчасно поповнити рахунок?
                        </h2>
                        <h3>
                            Замовте послугу "Довірений платіж" і працюйте в мережі Інтернет "без розривів"!
                            <br />
                            У будь-який час доби Ви можете скористатися послугою "Довірений платіж", і не турбуватися про те, що забули вчасно поповнити рахунок.
                            <br />
                            <br />
                            Робота в кредит доступна один раз на місяць і являє собою тимчасовий платіж на суму в розмірі 15 грн, якої буде достатньо для продовження роботи.
                        </h3>
                        <h3>
                            Тимчасовий платіж автоматично видалиться через 3 днi. Дана послуга коштує 2 грн.
                        </h3>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={9} align="center">
                            {message === '' && <CircularProgress />}
                            {message !== '' &&
                                (
                                 <Box color={messageColor}>
                                    <h3>{message}</h3>
                                    </Box>
                                )
                            }
                            </Grid>
                            <Grid item xs={12} sm={3} align="center">
                            {isUsed === 0 && message !== ''  && (
                                <form  className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
                                    <Button variant="contained" color="primary" size="large" onClick={useTrustedPayment}><CreditCardIcon />&nbsp;Використати</Button>
                                </form>
                            )}
                            </Grid>
                        </Grid>
                    </div>
            </Paper>
        </NavBar>
    );

}

export default TrustedPaymentComponent;
